import { useGSAP } from '@gsap/react'
import React, { useRef, useState } from 'react'
import gsap from 'gsap';
export default function Header() {

    const [toggleMenu ,setToggleMenu] = useState(false)
    const header = useRef();
   

    useGSAP(()=>{
        let tl = gsap.timeline()
        tl.to('.logo', { 'clip-path': 'polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%)', opacity:1, duration:1,delay:0.5, autoAlpha:1})
    },
    { scope: header }
    )

    return (
        <header ref={header} className='padding-left-right'>
            <div className='header-section'>
                <nav class="navbar navbar-expand-lg bg-body-tertiary">
                    <div class="container-fluid">
                        <a class="navbar-brand" href="#">
                            <img className='logo' src={require('../assets/img/logo.png')} />
                        </a>
                        <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                            <span class="navbar-toggler-icon"></span>
                        </button>
                        <div class="collapse navbar-collapse" id="navbarSupportedContent">
                            <ul class="navbar-nav me-auto mb-2 mb-lg-0">
                                {/* <li class="nav-item">
                                    <a class="nav-link active" aria-current="page" href="#">Home</a>
                                </li>
                                <li class="nav-item">
                                    <a class="nav-link" href="#">Link</a>
                                </li>
                                 */}
                            </ul>
                            <div className={`toggle-button ${toggleMenu ? 'active':''}`} onClick={()=>setToggleMenu(!toggleMenu)}>
                                {/* <span></span>
                                <span></span>
                                <span></span> */}
                            </div>
                        </div>
                    </div>
                </nav>
            </div>
        </header>
    )
}
