import React, { useRef } from 'react';
import { AvForm, AvField, AvGroup, AvInput, AvFeedback, AvRadioGroup, AvRadio, AvCheckboxGroup, AvCheckbox } from 'availity-reactstrap-validation';
import { Button, Label, FormGroup, CustomInput } from 'reactstrap';
import { Link } from 'react-router-dom';
import emailjs from '@emailjs/browser';
import { toast } from 'react-toastify';

export default function Footer(props) {
    const { setLoading } = props;
    const form = useRef();

    const handleValidSubmit = (e, v) => {
        setLoading(true);
        emailjs.send('service_esj8bh5', 'template_s8oltpv', v, {
            publicKey: 'ClCoQtmjgbf8ZZBLW',
        }).then(
            function (response) {
                // console.log('SUCCESS!', response.status, response.text);
                setLoading(false);
                toast.success("Success");
                form.current?.reset();
            },
            function (err) {
                console.log('FAILED...', err);
                setLoading(false);
                toast.error("Something Went Wrong, Try again!");
            },
        );
    }

    return (
        <footer className='footer top'>
            <div className='footer-top-section'>
                <div className='container-fluid'>
                    <div className='row'>
                        <div className='col-lg-7'>
                            <div className='left-section'>
                                <h4>Get in <span>touch</span></h4>
                                <p>Fill out the form or reach out to us directly through our email or phone number. Our team is here to assist you and provide the best solutions tailored to your needs.</p>
                                <ul className='ul-list'>
                                    <li className='left-col'>
                                        <h5>Gurugram</h5>
                                        <p>Haryana, India</p>
                                        <h5>Phone</h5>
                                        <Link to={"tel:+917500063621"}>+91 75000 63621 </Link>
                                        <Link to={"tel:+919889226111"}>+91 98892 26111</Link>
                                    </li>
                                    <li className='left-col'>
                                        <h5>Social</h5>
                                        <ul>
                                            <li><a href='#'><i class="fa-brands fa-facebook-f"></i></a></li>
                                            <li><a href='#'><i class="fa-brands fa-linkedin-in"></i></a></li>
                                            <li><a href='#'><i class="fa-brands fa-youtube"></i></a></li>
                                            <li><a href='#'><i class="fa-brands fa-instagram"></i></a></li>
                                        </ul>
                                        <h5>Email</h5>
                                        <Link to={'mailto:makemywebsite.live@gmail.com'}>makemywebsite.live@gmail.com</Link>
                                        <Link to={'mailto:devsol.app@gmail.com'}>devsol.app@gmail.com</Link>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className='col-lg-5'>
                            <div className='right-section'>
                                <AvForm onValidSubmit={handleValidSubmit} ref={form}>
                                    <div className='text_input'>
                                        <AvField name="name" label="Full Name *" placeholder="Enter your full name" required />
                                    </div>
                                    <div className='text_input'>
                                        <AvField type="email" name="email" label="Email *" placeholder="Enter your contact email" required />
                                    </div>

                                    <div className='text_input'>
                                        <AvField name="subject" label="Subject *" placeholder="Enter the purpose for contact" required />
                                    </div>
                                    <div className='text_input'>
                                        <AvField name="message" type="textarea" label="Message *" placeholder="how may we assist you." required />
                                    </div>
                                    <button className='btn' type='submit'>Send message</button>
                                </AvForm>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='copy_right'>
                    <p> © 2024 Make My Website. All Rights Reserved</p>
                    <p>Designed by <a href='https://devsol.in/' target='_blank' style={{ color: "#007119" }}>Devsol</a></p>
                </div>
            </div>
        </footer>
    )
}
