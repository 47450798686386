import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import './assets/css/style.css';
import './assets/css/clash-display.css';
import './assets/fonticon/css/all.min.css';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import Router from './route/Router';
import { useEffect, useRef } from 'react';

function App() {

  return (
    <div className="App">
      <ToastContainer />
      <Router />

    </div>
  );
}

export default App;
