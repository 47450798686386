import React from 'react';
// import { BallTriangle  } from 'react-loader-spinner';
import Lottie from 'react-lottie';
const loading = require('../../assets/lotti/loading.json');

const Loader = (props) => {
    if (props.visible)
        // if(true)
        return (
            <div style={{ width: '100%', height: '100%', alignItems: 'center', justifyContent: 'center', backgroundColor: '#ffffff88', zIndex: 1051, top: 0, display: 'flex', position: 'fixed' }}>
                {/* <BallTriangle
                    height={100}
                    width={150}
                    radius={5}
                    color="#324fc4"
                    secondaryColor= '#4fa94d'
                    ariaLabel="ball-triangle-loading"
                    wrapperClass={{}}
                    wrapperStyle={{}}
                    visible={true}
                /> */}
                {/* <img src={loading} style={{height:100, width:100, backgroundColor:'transparent'}} /> */}
                <Lottie
                    options={{
                        loop: true,
                        autoplay: true,
                        animationData: loading,
                        rendererSettings: {
                            preserveAspectRatio: "xMidYMid slice"
                        }
                    }}
                    height={300}
                    width={300}
                />
            </div>
        )
    else
        return null;
}

export default Loader;